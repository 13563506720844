import clsx from 'clsx';
import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { authStoreContext } from '../../stores/AuthStore';
import { AppButton } from '../core/AppButton';

interface Props {}

const Header = (props: Props) => {
	const { authentication } = useContext(authStoreContext);

	if (authentication) return null;

	return (
		<header className={clsx('z-50 text-white w-full fixed py-3 bg-main-blue')}>
			<div className="container flex items-center">
				<div className="text-2xl grow">
					<Link to="/">ThetaWave</Link>
				</div>
				<nav>
					<ul className="hidden lg:flex items-center gap-12 m-0 mr-12 text-base">
						<li>
							<a href="/#about">About</a>
						</li>
						<li>
							<a href="/#steps">Steps</a>
						</li>
						<li>
							<a href="/#price">Price</a>
						</li>
					</ul>
				</nav>
				<Link to="/login" className="mr-4 sm:mr-12">
					Log in
				</Link>
				<Link to="/#register">
					<AppButton className="!bg-white !text-blue-500">Sign Up</AppButton>
				</Link>
			</div>
		</header>
	);
};

export default observer(Header);
