import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { authStoreContext } from '../../stores/AuthStore';
import './style.scss';

interface Props {}

const Footer = (props: Props) => {
	const { authentication } = useContext(authStoreContext);

	if (authentication) return null;

	return (
		<footer className="text-white w-full bg-main-blue">
			<div className="container">
				<div className="footer__wrapper min-h-fit p-5 flex flex-row flex-wrap gap-6 justify-between items-center">
					<p className="text-center">© All Rights Reserved. App, {new Date().getFullYear()}</p>
					<nav className="footer__links flex gap-12 text-base">
						<Link to="/contacts">Contacts</Link>
						<Link to="/terms">Terms of service</Link>
						<Link to="/privacy-policy">Privacy Policy</Link>
					</nav>
				</div>
			</div>
		</footer>
	);
};

export default observer(Footer);
